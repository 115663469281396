<template>
  <v-dialog v-model="logoutModal" max-width="500px" persistent>
    <logout-modal />
  </v-dialog>
</template>

<script>
export default {
  mounted() {
    this.$store.commit("logoutModal", true);
  },
  beforeDestroy() {
    this.$store.commit("logoutModal", false);
  },
};
</script>

<style>
</style>