<template>
  <v-card class="mt-sm-8 mt-md-5 mt-7 bg-transparent">
    <div class="card-padding pb-0">
      <v-img src="@/assets/img/soap.png" class="" height="auto"> </v-img>
      <h4 class="text-h2 mt-10 font-weight-bolder text-typo mb-2">
        Passwort zurücksetzen
      </h4>
      <p class="mb-0 text-typo" v-if="!resetOk">
        Gebe deine Business E-Mail-Adresse um das Passwort zurückzusetzen.
      </p>
      <p class="mb-0 text-typo" v-else>
        Wir haben dir einen Code per E-Mail zugesendet, bitte gebe ihn jetzt
        ein. Überprüfe auch deinen Spam-Ordner.
      </p>
    </div>

    <div class="card-padding pb-4">
      <div v-if="!resetOk">
        <label class="text-xs text-typo font-weight-bolder ms-1"
          >Business E-Mail</label
        >
        <v-text-field
          v-model="form.email"
          :rules="emailRules"
          hide-details
          outlined
          background-color="rgba(255,255,255,.9)"
          color="rgba(0,0,0,.6)"
          light
          height="40"
          placeholder="Business E-Mail"
          class="input-style font-size-input border border-radius-md placeholder-lighter text-color-light mb-4 mt-2"
        >
        </v-text-field>

        <p class="text-danger">
          {{ authMessage.message }}
        </p>
        <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-bold text-uppercase btn-default bg-default py-2 px-6 me-2 mb-2 w-100"
          color="#5e72e4"
          small
          @click="submitForm"
          ><span v-if="!updatingAuth">Code senden</span>
          <v-progress-circular
            v-else
            indeterminate
            color="white"
          ></v-progress-circular
        ></v-btn>
      </div>

      <div v-else>
        <label class="text-xs text-typo font-weight-bolder ms-1"
          >6-Stelliger Code</label
        >
        <v-text-field
          v-model="form.code"
          :rules="min6"
          hide-details
          outlined
          background-color="rgba(255,255,255,.9)"
          color="rgba(0,0,0,.6)"
          light
          height="40"
          placeholder="123456"
          class="input-style font-size-input border border-radius-md placeholder-lighter text-color-light mb-4 mt-2"
        >
        </v-text-field>

        <p class="text-danger mt-3">
          {{ authMessage.message }}
        </p>

        <label class="text-xs text-typo font-weight-bolder ms-1"
          >Neues Passwort *</label
        >
        <v-text-field
          hide-details
          outlined
          v-model="form.password"
          :rules="min8"
          background-color="rgba(255,255,255,.9)"
          color="rgba(0,0,0,.6)"
          light
          height="40"
          placeholder="Passwort"
          type="password"
          class="input-style font-size-input border border-radius-md placeholder-lighter text-color-light mb-4 mt-2"
        >
        </v-text-field>
        <div class="d-sm-flex">
          <ul class="text-muted ps-6 mb-0">
            <li :key="'validateSymbol' + validateSymbol">
              <v-icon
                class="mr-2"
                :class="validateSymbol ? 'text-success' : 'text-danger'"
                size="12"
                >{{ validateSymbol ? "fa fa-check" : "fa fa-times" }}</v-icon
              >
              <span class="text-sm">Ein Sonderzeichen </span>
            </li>
            <li :key="'validateLength' + validateLength">
              <v-icon
                class="mr-2"
                :class="validateLength ? 'text-success' : 'text-danger'"
                size="12"
                >{{ validateLength ? "fa fa-check" : "fa fa-times" }}</v-icon
              >
              <span class="text-sm">Mindestens 8 Zeichen</span>
            </li>
            <li :key="'validateNumber' + validateNumber">
              <v-icon
                class="mr-2"
                :class="validateNumber ? 'text-success' : 'text-danger'"
                size="12"
                >{{ validateNumber ? "fa fa-check" : "fa fa-times" }}</v-icon
              >
              <span class="text-sm">Eine Nummer (2 werden empfohlen)</span>
            </li>
          </ul>
        </div>

        <v-btn
          :disabled="
            !form.email ||
            !form.code ||
            !validateSymbol ||
            !validateLength ||
            !validateNumber
          "
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-bold mt-3 text-uppercase btn-default bg-default py-2 px-6 me-2 mb-2 w-100"
          color="#5e72e4"
          small
          @click="submitFormConfirm"
          ><span v-if="!updatingAuth">Passwort festlegen</span>
          <v-progress-circular
            v-else
            indeterminate
            color="white"
          ></v-progress-circular
        ></v-btn>
      </div>

      <div class="text-center">
        <p class="text-sm text-body mt-3 mb-0">
          Zurück zum
          <a
            @click="$router.push('/Login').catch(() => {})"
            class="text-warning text-gradient text-decoration-none font-weight-bold"
            >Login</a
          >
        </p>
      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "ResetPwd",
  data() {
    return {
      checkbox: false,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 10 || "Name must be less than 10 characters",
      ],
      emailRules: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Bitte gebe eine gültige E-Mail-Adresse an",
      ],
      rules: [
        (value) => !!value || "Erforderlich.",
        (value) => (value && value.length >= 3) || "Min 3 Zeichen",
      ],
      min8: [(value) => (value && value.length >= 7) || "Min 8 Zeichen"],
      min6: [
        (value) =>
          (value && value.length > 5 && value.length < 7) || "6 Zeichen",
      ],
      loginDialog: false,
      registerDialog: false,
      ratingDialog: false,
      suggestionDialog: false,
      dialog: false,
      form: {
        email: "",
        password: "",
      },
    };
  },
  computed: {
    updating() {
      return this.$store.getters.updating;
    },
    authMessage() {
      return this.$store.getters.authMessage;
    },
    validateSymbol() {
      let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

      if (format.test(this.form.password)) {
        return true;
      } else {
        return false;
      }
    },
    validateLength() {
      return this.form.password.length >= 8;
    },
    validateNumber() {
      return /\d/.test(this.form.password);
    },
  },
  methods: {
    submitForm() {
      let payload = {
        email: this.form.email,
        password: this.form.password,
      };
      this.$store.dispatch("resetPassword", payload);
      // this.$store.dispatch("loginModal", payload);
    },

    submitFormConfirm() {
      let payload = {
        email: this.form.email,
        code: this.form.code,
        password: this.form.password,
      };
      this.$store.dispatch("confirmResetPassword", payload);
      // this.$store.dispatch("loginModal", payload);
    },
  },
};
</script>
