<template>
  <v-card class="mt-sm-8 mt-md-5 mt-7 bg-transparent">
    <div class="card-padding pb-0">
      <v-img src="@/assets/img/soap.png" class="" height="auto"> </v-img>

      <div v-if="!accountCreated">
        <h4 class="text-h2 mt-0 mt-md-10 font-weight-bolder text-typo mb-2">
          Registrieren
        </h4>
        <p class="mb-0 text-typo">
          Geben Sie Ihre Business E-Mail-Adresse und Ihr Passwort ein, um sich
          zu registrieren.
        </p>
      </div>
      <div v-else>
        <h4 class="text-h2 mt-0 mt-md-10 font-weight-bolder text-typo mb-2">
          Bitte bestätigen Sie Ihre E-Mail-Adresse.
        </h4>
        <p class="mb-0 text-typo">
          Wir haben Ihnen einen Code per E-Mail gesendet, bitte geben sie ihn
          hier ein:
        </p>
      </div>
    </div>
    <div class="card-padding pb-4" v-if="!accountCreated">
      <label class="text-xs text-typo font-weight-bolder ms-1">Vorname *</label>
      <v-text-field
        v-model="form.firstname"
        :rules="rules"
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        placeholder="Vorname"
        class="input-style font-size-input border border-radius-md placeholder-lighter text-color-light mb-4 mt-2"
      >
      </v-text-field>

      <label class="text-xs text-typo font-weight-bolder ms-1"
        >Nachname *</label
      >
      <v-text-field
        v-model="form.lastname"
        :rules="rules"
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        placeholder="Nachname"
        class="input-style font-size-input border border-radius-md placeholder-lighter text-color-light mb-4 mt-2"
      >
      </v-text-field>

      <label class="text-xs text-typo font-weight-bolder ms-1">E-Mail *</label>
      <v-text-field
        v-model="form.email"
        :rules="formRules.emailRules"
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        placeholder="E-Mail"
        class="input-style font-size-input border border-radius-md placeholder-lighter text-color-light mb-4 mt-2"
      >
      </v-text-field>

      <label class="text-xs text-typo font-weight-bolder ms-1"
        >Passwort *</label
      >
      <v-text-field
        hide-details
        outlined
        v-model="form.password"
        :rules="min8"
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        placeholder="Passwort"
        type="password"
        class="input-style font-size-input border border-radius-md placeholder-lighter text-color-light mb-4 mt-2"
      >
      </v-text-field>
      <div class="d-sm-flex">
        <ul class="text-muted ps-6 mb-0">
          <li :key="'validateSymbol' + validateSymbol">
            <v-icon
              class="mr-2"
              :class="validateSymbol ? 'text-success' : 'text-danger'"
              size="12"
              >{{ validateSymbol ? "fa fa-check" : "fa fa-times" }}</v-icon
            >
            <span class="text-sm">Ein Sonderzeichen </span>
          </li>
          <li :key="'validateLength' + validateLength">
            <v-icon
              class="mr-2"
              :class="validateLength ? 'text-success' : 'text-danger'"
              size="12"
              >{{ validateLength ? "fa fa-check" : "fa fa-times" }}</v-icon
            >
            <span class="text-sm">Mindestens 8 Zeichen</span>
          </li>
          <li :key="'validateNumber' + validateNumber">
            <v-icon
              class="mr-2"
              :class="validateNumber ? 'text-success' : 'text-danger'"
              size="12"
              >{{ validateNumber ? "fa fa-check" : "fa fa-times" }}</v-icon
            >
            <span class="text-sm">Eine Nummer (2 werden empfohlen)</span>
          </li>
        </ul>
      </div>

      <v-checkbox
        :key="checkbox"
        v-model="checkbox"
        color="#141727"
        :ripple="false"
        class="ma-0 mt-3 checkbox-custom checkbox-thinner"
        hide-details
      >
        <template v-slot:label>
          <span class="text-typo text-body-2 mb-2 ls-0"
            >Ich akzeptiere die
          </span>
        </template>
      </v-checkbox>
      <div>
        <a
          href="https://soap-software.de/datenschutz"
          target="_blank"
          class="text-dark pb-5 text-center font-weight-bolder text-decoration-none"
          >Datenschutzbestimmungen</a
        >
      </div>

      <p class="text-danger my-5" v-if="authMessage">
        {{ authMessage.message }}
      </p>
      <v-btn
        elevation="0"
        :ripple="false"
        :disabled="
          !checkbox ||
          !form.email ||
          !form.firstname ||
          !form.lastname ||
          !validateSymbol ||
          !validateLength ||
          !validateNumber
        "
        height="43"
        class="font-weight-bold text-uppercase btn-warning bg-warning py-2 px-6 me-2 mb-2 w-100 mt-5"
        color="#5e72e4"
        small
        @click="submitFormRegister"
        >Registrieren</v-btn
      >
      <div class="text-center">
        <p class="text-sm text-body mt-3 mb-0">
          Sie haben bereits einen Account?
          <a
            @click="$router.push('/login').catch(() => {})"
            class="text-warning text-gradient text-decoration-none font-weight-bold"
            >Einloggen</a
          >
        </p>
      </div>
    </div>
    <div class="card-padding pb-4" v-if="accountCreated">
      <p class="text-danger">
        {{ authMessage.message }}
      </p>
      <v-text-field
        placeholder="6-Stelliger Code"
        v-model="form.verificationCode"
        type="number"
        :rules="min6"
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        class="input-style font-size-input border border-radius-md placeholder-lighter text-color-light mb-4 mt-2"
      ></v-text-field>

      <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        :disabled="form.verificationCode.length != 6"
        class="font-weight-bold text-uppercase btn-default py-2 px-6 me-2 mt-6 mb-2 w-100"
        :class="form.verificationCode.length != 6 ? '' : 'bg-warning'"
        color="#5e72e4"
        small
        @click="verifyEmail"
        ><span v-if="!updatingAuth">Code bestätigen</span>
        <v-progress-circular
          v-else
          indeterminate
          color="white"
        ></v-progress-circular>
      </v-btn>

      <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        class="font-weight-bold text-uppercase btn-default bg-warning py-2 px-6 me-2 mt-6 mb-2 w-100"
        color="#5e72e4"
        small
        :disabled="codeSent"
        @click="
          $store.dispatch('resendCode', {
            email: userInformations.email,
            resendActivation: true,
          })
        "
        ><span v-if="!updatingAuth"
          >Neue{{ codeSent ? "r" : "n" }} Code
          {{ codeSent ? "wurde gesendet" : "senden" }}</span
        >
        <v-progress-circular
          v-else
          indeterminate
          color="white"
        ></v-progress-circular>
      </v-btn>
      <p class="text-center">{{ userInformations.email }}</p>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "signup",
  data() {
    return {
      checkbox: false,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 10 || "Name must be less than 10 characters",
      ],

      rules: [
        (value) => !!value || "Erforderlich.",
        (value) => (value && value.length >= 3) || "Min 3 Zeichen",
      ],
      min8: [(value) => (value && value.length >= 7) || "Min 8 Zeichen"],
      min6: [
        (value) =>
          (value && value.length > 5 && value.length < 7) || "6 Zeichen",
      ],
      loginDialog: false,
      registerDialog: false,
      ratingDialog: false,
      suggestionDialog: false,
      dialog: false,
      form: {
        verificationCode: "",
        password: "",
      },
    };
  },
  computed: {
    userInformations() {
      return this.$store.getters.userInformations;
    },
    accountCompleted() {
      return this.$store.getters.accountCompleted;
    },
    codeSent() {
      return this.$store.getters.codeSent;
    },
    accountCreated() {
      return this.$store.getters.accountCreated;
    },
    authMessage() {
      return this.$store.getters.authMessage;
    },
    updatingAuth() {
      return this.$store.getters.updatingAuth;
    },
    validateSymbol() {
      let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

      if (format.test(this.form.password)) {
        return true;
      } else {
        return false;
      }
    },
    validateLength() {
      return this.form.password.length >= 8;
    },
    validateNumber() {
      return /\d/.test(this.form.password);
    },
  },
  methods: {
    submitFormRegister() {
      if (!this.form.email || !this.form.password || !this.checkbox) return;
      let payload = {
        email: this.form.email,
        password: this.form.password,
        firstname: this.form.firstname,
        lastname: this.form.lastname,
      };
      this.$store.dispatch("signup", payload);
    },
    verifyEmail() {
      let payload = {
        code: this.form.verificationCode,
      };
      this.$store.dispatch("verifyEmail", payload);
    },
    openLink(link) {
      window.open(`https://soap-software.de/${link}`, "_blank");
    },
  },
};
</script>
