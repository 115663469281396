<template>
  <v-row class="justify-center d-flex mt-n16 mx-0 position-relative px-4">
    <v-col cols="12" md="8" class="mt-15" style="margin-top: 100px">
      <v-container class="mb-15 mt-15">
        <h2
          class="mx-3 h1 font-weight-normal text-center mb-10 mt-10 text-body"
          style="font-size: 35px"
        >
          404 <br />
          <br />
          Wir haben den gesuchten Inhalt nicht gefunden...
        </h2>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "404",
  data() {
    return {
      tab: "",
    };
  },
};
</script>
